import dayjs, { Dayjs } from 'dayjs'
import { get } from 'lodash'
import { NextPageContext } from 'next'

import Router from 'next/router'

export * from './getImagePath'
export * from './readableColor'
export * from './getErrorMsg'

export type RedirectPageParams = {
  path: string
  params?: Record<string, string>
}

export function redirectPage(
  { path, params = {} }: RedirectPageParams,
  ctx?: NextPageContext,
): void {
  const queryString = new URLSearchParams(params).toString()
  const url = `${path}${queryString && '?' + queryString}`

  if (process.browser) {
    Router.replace(url)
  } else if (ctx && ctx.res) {
    ctx.res.writeHead(302, { Location: url })
    ctx.res.end()
  }
}

export const formatDateWithTime = (
  date: Dayjs | string,
  format = 'YYYY-MM-DD HH:mm:ss',
): string => {
  return date ? dayjs(date).format(format) : 'N/A'
}

const policyNameMapping = {
  privacy: {
    default: 'Privacy policy',
    full: 'Privacy policy',
  },
  hr_privacy: {
    default: 'HR policy',
    full: 'HR privacy policy',
  },
  cookie: {
    default: 'Cookies policy',
    full: 'Cookies policy',
  },
  cctv: {
    default: 'CCTV policy',
    full: 'CCTV privacy policy',
  },
  dpa: {
    default: 'DPA',
    full: 'Data processing Agreement',
  },
  recruitment_privacy: {
    default: 'Recruitment Policy',
    full: 'Recruitment Privacy Policy',
  },
  vendor: {
    default: 'Vendor Policy',
    full: 'Vendor Privacy Policy',
  },
}

export const formatNumber = (value: number): string => {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const getPolicyName = (template: string, type = 'default'): string =>
  get(policyNameMapping, `${template}.${type}`)

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max)
}

export * from './createContext'
