import { Fragment, FunctionComponent, ReactNode, useEffect } from 'react'
import { NextComponentType } from 'next'
import App, { AppContext, AppProps as NextAppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { flowRight as compose } from 'lodash'
import { DefaultSeo } from 'next-seo'

import { ThemeProvider } from '@/lib/styles'
import withAuth from '@/lib/auth/withAuth'
import { ErrorProvider } from '@/lib/error'
import { withResponsiveProvider } from '@/lib/responsive'
import { UTMContextProvider } from '@/lib/gtm/utmContext'

import envObj from '@/config/env'

import { DEFAULT_SEO_CONFIG } from '../constants/seo'

const Noop = ({ children }: { children: ReactNode }) => children

type PageComponentType = NextComponentType & {
  Layout?: FunctionComponent
}

type AppProps = NextAppProps & PageProps

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
})

function MyApp(props: AppProps): ReactNode {
  const { router, userInfo } = props
  const Component: PageComponentType = props.Component
  const ssPageProps = { ...props.pageProps, router, userInfo }

  const Layout = (Component.Layout || Noop) as FunctionComponent

  useEffect(() => {
    if (window.howuku && userInfo) {
      window.howuku.identify({
        email: userInfo.email,
        company: userInfo.company_name ?? '',
      })
    }
  }, [userInfo?.email])

  const url = `${envObj.APP_URL}${router.asPath}`

  return (
    <Fragment>
      <DefaultSeo
        {...DEFAULT_SEO_CONFIG}
        openGraph={{
          ...DEFAULT_SEO_CONFIG.openGraph,
          url: url,
        }}
        canonical={url}
      />

      <UTMContextProvider>
        <ThemeProvider>
          <ErrorProvider>
            <QueryClientProvider client={queryClient}>
              <Layout {...ssPageProps}>
                <Component {...ssPageProps} />
              </Layout>

              <ReactQueryDevtools
                position="bottom-right"
                initialIsOpen={false}
              />
            </QueryClientProvider>
          </ErrorProvider>
        </ThemeProvider>
      </UTMContextProvider>
    </Fragment>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)

  return { ...appProps }
}

export default compose(withAuth, withResponsiveProvider)(MyApp)
