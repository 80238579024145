import * as Cookies from 'js-cookie'
import { get, isEmpty } from 'lodash'
import nextCookie from 'next-cookies'

import { COOKIE_AUTH_DATA } from './constant'
import {
  COOKIE_HIDE_CROSS_SALE_BANNER,
  COOKIE_HIDE_COOKIE_ADS_BANNER,
} from '@/features/dashboard/constants'
import { COOKIE_HIDE_TRANSPARENCY_BANNER } from '@/features/policies/CreatePolicy/constants'
import * as AuthServices from './AuthServices'

export function setUserCookie(headers, name = COOKIE_AUTH_DATA) {
  const { ac, client, uid, expiry } = headers
  const value = { ac, client, uid }

  Cookies.set(name, value, {
    expires: new Date(expiry * 1000),
  })
}

export function deleteUserCookie(name = COOKIE_AUTH_DATA) {
  Cookies.remove(name)
  Cookies.remove(COOKIE_HIDE_CROSS_SALE_BANNER)
  Cookies.remove(COOKIE_HIDE_COOKIE_ADS_BANNER)
  Cookies.remove(COOKIE_HIDE_TRANSPARENCY_BANNER)
}

export async function userAuth(context) {
  const userData = getUserData(context)

  // Validate token on Server-side
  if (context.req) {
    try {
      return await AuthServices.validateToken(userData)
    } catch (err) {
      deleteUserCookie()
      return false
    }
  }

  // On client side just check user data in cookie
  return !isEmpty(userData)
}

export function getUserData(context, name = COOKIE_AUTH_DATA) {
  const cookies = nextCookie(context)

  return get(cookies, name)
}
