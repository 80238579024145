import { get } from 'lodash'

function getErrorMsg(error) {
  if (error.response) {
    if (typeof error.response.data === 'string') {
      return error.response.data
    }

    const errors = get(error, 'response.data.errors', [])

    if (errors.length > 0) {
      return errors[0]
    }

    if (errors.full_messages) {
      return get(errors, 'full_messages[0]', '')
    }

    const errorMsg = get(error, 'response.data.error', '')

    if (errorMsg !== '') {
      return errorMsg
    }

    return 'Something Wrong'
  } else if (error.request) {
    return 'Network Error'
  }

  return 'Something Wrong'
}

export default getErrorMsg
