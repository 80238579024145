import { get, isEmpty } from 'lodash'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import envObj from '@/config/env'
import { getBaseUrl } from './helpers'
import getUserCookie from '@/lib/auth/getUserCookie'

type FetchParams = {
  url?: string
  prefix?: string
  path: string
} & Omit<AxiosRequestConfig, 'baseURL'>

const _axios = axios.create()
_axios.interceptors.request.use(async (config) => {
  const userData = getUserCookie()

  const authHeader = {
    'access-token': get(userData, 'ac'),
    client: get(userData, 'client'),
    uid: get(userData, 'uid'),
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(!isEmpty(userData) && authHeader),
    },
  }
})

// change structure of fetcher
export function fetchAPIv2<T = any>({
  url = envObj.API_URL,
  prefix = '/api/v1',
  path,
  ...options
}: FetchParams): Promise<AxiosResponse<T>> {
  return _axios.request<T>({
    baseURL: getBaseUrl({ apiUrl: url, apiPrefix: prefix, path }),
    ...options,
  })
}
