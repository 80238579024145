import React, { useState, useEffect } from 'react'
import { useMediaQuery, Context as ResponsiveContext } from 'react-responsive'

export const withResponsiveProvider = (PageComponent) => {
  function EnhancedPageComponent(props) {
    const [width, setWidth] = useState()

    useEffect(() => {
      const listener = () => {
        setWidth(window.innerWidth)
      }

      listener()

      window.addEventListener('resize', listener, { passive: true })

      return () => {
        window.removeEventListener('resize', listener)
      }
    }, [])

    return (
      <ResponsiveContext.Provider value={{ width }}>
        <PageComponent {...props} />
      </ResponsiveContext.Provider>
    )
  }

  EnhancedPageComponent.getInitialProps = async function (appContext) {
    let appProps = {}

    if (typeof PageComponent.getInitialProps === 'function') {
      appProps = await PageComponent.getInitialProps(appContext)
    }

    return appProps
  }

  return EnhancedPageComponent
}

// For Switch Component
export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 })

  return isDesktop ? children : null
}

export const MediumPlusDesktop = ({ children }) => {
  const isMediumPlusDesktop = useMediaQuery({ minWidth: 1280 })

  return isMediumPlusDesktop ? children : null
}

export const LargeDesktop = ({ children }) => {
  const isLargeDesktop = useMediaQuery({ minWidth: 1440 })

  return isLargeDesktop ? children : null
}

export const TabletOrMobile = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ minWidth: 350, maxWidth: 1023 })

  return isTabletOrMobile ? children : null
}

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })

  return isTablet ? children : null
}

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 350, maxWidth: 767 })

  return isMobile ? children : null
}

export const Default = ({ children }) => {
  const isDefault = useMediaQuery({ minWidth: 768 })

  return isDefault ? children : null
}

export const breakpoints = {
  xs: '375px',
  sm: '768px',
  md: '1024px',
  'md+': '1280px',
  lg: '1440px',
  xl: '1920px',
  xxl: '2560px',
}

export function mq(bp = 'lg') {
  return `@media (min-width: ${breakpoints[bp]})`
}

export function useMQ() {
  const isLargeDesktop = useMediaQuery({ minWidth: 1440 })
  const isDesktopPlus = useMediaQuery({ minWidth: 1024, maxWidth: 1279 })
  const isDesktop = useMediaQuery({ minWidth: 1024 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
  const isMobile = useMediaQuery({ minWidth: 350, maxWidth: 767 })
  const isTabletOrMobile = useMediaQuery({ minWidth: 350, maxWidth: 1023 })

  return {
    isLargeDesktop,
    isDesktopPlus,
    isDesktop,
    isTablet,
    isMobile,
    isTabletOrMobile,
  }
}
