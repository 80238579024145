import '@/lib/init/prototype'

const circleBranch = process.env.CIRCLE_BRANCH

const envObj = {
  API_URL: process.env.API_URL,
  APP_KEY: process.env.APP_KEY,
  APPLICATION_KEY: process.env.APPLICATION_KEY,
  ASSET_PREFIX: process.env.ASSET_PREFIX || '/',
  APP_URL: process.env.APP_URL || 'http://localhost:3000',
  CRISP_WEBSITE_ID: process.env.CRISP_WEBSITE_ID,
  BLOG_API_KEY: process.env.BLOG_API_KEY,
  BLOG_API_URL: process.env.BLOG_API_URL,
  STRIPE_PKEY: process.env.STRIPE_PKEY_TEST,
  COOKIE_BANNER_ID: process.env.COOKIE_BANNER_ID,
  IS_DEV: true,
  SHOW_ALERT_BANNER: process.env.SHOW_ALERT_BANNER,
  MAIL_TO: process.env.MAIL_TO,
  EMAIL_API_URL: process.env.EMAIL_API_URL,
  SLACK_API_URL: process.env.SLACK_API_URL,
  GTM_CONTAINER_ID: '',
  SURVEYSENSUM_URL: process.env.SURVEYSENSUM_URL,
  SLACK_WEBHOOK_URL: process.env.STAGING_SLACK_WEBHOOK_URL,

  CONSENT_WOW_API_URL: process.env.CONSENT_WOW_API_URL || '',
  CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID:
    process.env.STAGING_CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID || '',
  CONSENT_WOW_CONTACT_API_KEY:
    process.env.STAGING_CONSENT_WOW_CONTACT_API_KEY || '',
  CONSENT_WOW_CHECK_WEBSITE_API_KEY:
    process.env.STAGING_CONSENT_WOW_CHECK_WEBSITE_API_KEY || '',
  CONSENT_WOW_SIGN_UP_API_KEY:
    process.env.STAGING_CONSENT_WOW_SIGN_UP_API_KEY || '',
  CONSENT_WOW_OTHER_PRODUCTS_API_KEY:
    process.env.STAGING_CONSENT_WOW_OTHER_PRODUCTS_API_KEY || '',
  CONSENT_WOW_PRIVACY_SETTING_API_KEY:
    process.env.STAGING_CONSENT_WOW_PRIVACY_SETTING_API_KEY || '',
}

if (circleBranch === 'production') {
  envObj.APP_URL = process.env.PRODUCTION_APP_URL
  envObj.API_URL = process.env.PRODUCTION_API_URL
  envObj.GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID
  envObj.BLOG_API_KEY = process.env.PRODUCTION_BLOG_API_KEY
  envObj.BLOG_API_URL = process.env.PRODUCTION_BLOG_API_URL
  envObj.STRIPE_PKEY = process.env.STRIPE_PKEY
  envObj.COOKIE_BANNER_ID = process.env.PRODUCTION_COOKIE_BANNER_ID
  envObj.IS_DEV = false
  envObj.SHOW_ALERT_BANNER = process.env.PRODUCTION_SHOW_ALERT_BANNER
  envObj.APP_KEY = process.env.PRODUCTION_APP_KEY
  envObj.SURVEYSENSUM_URL = process.env.PRODUCTION_SURVEYSENSUM_URL
  envObj.SLACK_WEBHOOK_URL = process.env.PRODUCTION_SLACK_WEBHOOK_URL

  envObj.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID =
    process.env.PRODUCTION_CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID
  envObj.CONSENT_WOW_CONTACT_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_CONTACT_API_KEY
  envObj.CONSENT_WOW_CHECK_WEBSITE_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_CHECK_WEBSITE_API_KEY
  envObj.CONSENT_WOW_SIGN_UP_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_SIGN_UP_API_KEY
  envObj.CONSENT_WOW_OTHER_PRODUCTS_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_OTHER_PRODUCTS_API_KEY
  envObj.CONSENT_WOW_PRIVACY_SETTING_API_KEY =
    process.env.PRODUCTION_CONSENT_WOW_PRIVACY_SETTING_API_KEY
}

if (circleBranch === 'main') {
  envObj.APP_URL = process.env.STAGING_APP_URL
  envObj.API_URL = process.env.STAGING_API_URL
  envObj.BLOG_API_KEY = process.env.STAGING_BLOG_API_KEY
  envObj.BLOG_API_URL = process.env.STAGING_BLOG_API_URL
  envObj.COOKIE_BANNER_ID = process.env.STAGING_COOKIE_BANNER_ID
  envObj.SHOW_ALERT_BANNER = process.env.STAGING_SHOW_ALERT_BANNER
  envObj.APP_KEY = process.env.STAGING_APP_KEY
  envObj.SURVEYSENSUM_URL = process.env.STAGING_SURVEYSENSUM_URL
  envObj.SLACK_WEBHOOK_URL = process.env.STAGING_SLACK_WEBHOOK_URL
}

export default { ...envObj }
