import Button from './button'
import Checkbox from './checkbox'
import Input from './input'
import Link from './link'

export default {
  Button,
  Checkbox,
  Input,
  Link,
}
