import axios from 'axios'
import { get, isEmpty } from 'lodash'

import envObj from '@/config/env'
import getUserCookie from '@/lib/auth/getUserCookie'

export function getBaseUrl({ apiUrl = envObj.API_URL, apiPrefix, path }) {
  const urlObject = {
    apiUrl,
    ...(apiPrefix && { apiPrefix }),
    ...(path && { path }),
  }

  return Object.values(urlObject).join('')
}

export function fetchAPI({
  apiUrl = envObj.API_URL,
  apiPrefix = '/api/v1',
  path,
  userData = getUserCookie(),
  ...options
}) {
  const authHeader = {
    'access-token': get(userData, 'ac'),
    client: get(userData, 'client'),
    uid: get(userData, 'uid'),
  }

  return axios({
    baseURL: getBaseUrl({ apiUrl, apiPrefix, path }),
    ...(!isEmpty(userData) && { headers: authHeader }),

    ...options,
  })
}
