declare global {
  export interface String {
    toCapitalize: () => string
  }
}

String.prototype.toCapitalize = function () {
  return this.charAt(0).toUpperCase() + this.substring(1)
}

export {}
