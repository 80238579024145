import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

import customTheme from './theme'

function ThemeProvider(props) {
  return (
    <ChakraProvider resetCSS theme={customTheme}>
      <Global styles={GlobalStyles} />
      {props.children}
    </ChakraProvider>
  )
}

const GlobalStyles = css`
  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  @font-face {
    font-family: 'Anakotmai';
    src: url('/fonts/anakotmai-bold.woff2');
    font-weight: bold;
    font-display: swap;
  }

  @font-face {
    font-family: 'Anakotmai';
    src: url('/fonts/anakotmai-medium.woff2');
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Anakotmai';
    src: url('/fonts/anakotmai-light.woff2');
    font-weight: 300;
    font-display: swap;
  }

  :root {
    --text-base-size: 16px;
    --font-primary: Anakotmai, sans-serif;
    --font-color: #2b2b2b;
    --font-weight: 500;

    --body-line-height: 1.5;
    scroll-behavior: smooth;
  }

  body {
    font-size: var(--text-base-size);
    font-family: var(--font-primary);
    font-weight: var(---font-weight);
    color: var(--font-color);
    line-height: var(--body-line-height);
  }

  a {
    color: #ff7315;
    cursor: pointer;
  }
`

export default ThemeProvider
