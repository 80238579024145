import * as z from 'zod'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'

import { createCtx } from '@/lib/helpers'

export const UTMDataSchema = z
  .object({
    id: z.string().optional(),
    term: z.string().optional(),
    source: z.string().optional(),
    medium: z.string().optional(),
    campaign: z.string().optional(),
    content: z.string().optional(),
  })
  .optional()

export type UTMData = Exclude<z.infer<typeof UTMDataSchema>, undefined>

export type UTMContext = {
  data: UTMData
}

export type UTMContextProviderProps = {
  children: React.ReactNode
}

const contextResult = createCtx<UTMContext>()
const Provider = contextResult[1]
export const useUTMContext: () => UTMContext = contextResult[0]

export const UTMContextProvider = ({
  children,
}: UTMContextProviderProps): React.ReactElement => {
  const { query } = useRouter()

  const [data, setData] = useState<UTMData>({})
  const initialRef = useRef(false)

  useEffect(() => {
    if (initialRef.current) return

    initialRef.current = true

    setData({
      id: query?.utm_id || '',
      term: query?.utm_term || '',
      source: query?.utm_source || '',
      medium: query?.utm_medium || '',
      campaign: query?.utm_campaign || '',
      content: query?.utm_content || '',
    } as UTMData)
  }, [query])

  return <Provider value={{ data }}>{children}</Provider>
}
