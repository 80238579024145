// For Using in CSS prop
const Colors = {
  primary: {
    50: '#ffeedb',
    100: '#ffd0ae',
    200: '#ffb27e',
    300: '#ff944c',
    400: '#ff7315',
    500: '#ff761a',
    600: '#e65c00',
    700: '#b44800',
    800: '#813200',
    900: '#4f1d00',
  },
  secondary: {
    25: '#d7fffe',
    50: '#abfff7',
    100: '#7afff0',
    200: '#48ffe9',
    300: '#1affe3',
    400: '#00ceb5',
    500: '#00b39d',
    600: '#008170',
    700: '#004e43',
    800: '#001c16',
  },
  gray: {
    100: '#f6f6f6',
    500: '#b2b2b2',
    600: '#888888',
  },
  customGray: {
    100: '#f6f6f6',
    300: '#e8e8e8',
    400: '#cccccc',
    500: '#b2b2b2',
    700: '#888888',
    900: '#2b2b2b',
  },
  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#6AC761',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },
  PRIMARY_DEFAULT: '#ff7315',
  PRIMARY_LIGHT: '#ffb27e',

  BLOOD_RED: '#c63636',

  WHITE: '#ffffff',

  SMOKE_GREY: '#f6f6f6',
  WHISPER_GREY: '#e8e8e8',
  MEDIUM_GREY: '#D7DCE2',
  IRON_GREY: '#cccccc',
  MAGNESIUM_GREY: '#b2b2b2',
  JUMBO_GRAY: '#888888',
  JAGUAR_BLACK: '#2b2b2b',
  DARK_GREY: '#7F878F',
  DARK_BLUE: '#1B2938',
}

export default Colors
