const baseStyle = {
  transition: `all 0.15s ease-out`,
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'PRIMARY_DEFAULT',
  _hover: {
    textDecoration: 'underline',
  },
  _focus: {
    boxShadow: 'outline',
  },
}

export default {
  baseStyle,
}
