import React from 'react'
import { get } from 'lodash'
import { AxiosResponse, AxiosError } from 'axios'
import Router from 'next/router'

import { Error400, Error500 } from '@/components/Error'
import { redirectPage } from '@/lib/helpers'

interface Props {
  children?: React.ReactNode
}

interface State {
  hasRuntimeError: boolean
  error: AxiosResponse | null
}

class ErrorProvider extends React.PureComponent<Props, State> {
  state = {
    hasRuntimeError: false,
    error: null,
  }

  static getDerivedStateFromError(error: AxiosError): State {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).

    const errorResponse = get(error, 'response', null)
    const statusCode = get(errorResponse, 'status', 200)

    if (statusCode === 401) {
      redirectPage({
        path: '/login',
        params: {
          redirect_url: Router.asPath,
        },
      })
    }

    return {
      hasRuntimeError: !errorResponse,
      error: errorResponse,
    }
  }

  render(): React.ReactNode {
    const statusCode = get(this.state, 'error.status', 200)

    if (this.state.hasRuntimeError) {
      return <Error500 />
    }

    if (statusCode >= 500) {
      return <Error500 />
    }

    if (statusCode >= 404) {
      return <Error400 />
    }

    return this.props.children
  }
}

export default ErrorProvider
