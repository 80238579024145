import { get } from 'lodash'
import { AxiosPromise } from 'axios'

import { fetchAPI } from '@/lib/api'
import { UserInfo, PrivacySettingForm } from './types'

export const updateMarketingConsent = (
  data: PrivacySettingForm,
): AxiosPromise<unknown> => {
  return fetchAPI({
    method: 'put',
    path: '/auth',
    data,
  })
}

export const getUserInfo = (
  userData: UserData,
): Promise<UserInfo | unknown> => {
  return fetchAPI({
    path: `/me`,
    method: 'get',
    userData,
  }).then((res) => {
    return get(res, 'data.user') as UserInfo
  })
}
