import { authRoutes } from './authRoutes'

export const routes = [
  {
    name: 'home',
    pathname: '/',
  },
  {
    name: 'faq',
    pathname: '/faq',
  },
  {
    name: 'ourProducts',
    pathname: '/our-products',
  },
  {
    name: 'terms',
    pathname: '/terms',
  },
  {
    name: 'privacy',
    pathname: '/privacy',
  },
  {
    name: 'referral',
    pathname: '/referral',
  },
  {
    name: 'referralTerms',
    pathname: '/referral/terms',
  },
  {
    name: 'blogs',
    pathname: '/blogs',
  },
  {
    name: 'blogShow',
    pathname: '/blogs/[slug]',
  },
  {
    name: 'blogsTag',
    pathname: '/blogs/tags/[tagId]',
  },
  {
    name: 'serviceCCTV',
    pathname: '/services/cctv',
  },
  {
    name: 'serviceCookie',
    pathname: '/services/cookie',
  },
  {
    name: 'serviceDPA',
    pathname: '/services/dpa',
  },
  {
    name: 'serviceHRPrivacy',
    pathname: '/services/hr-privacy',
  },
  {
    name: 'servicePrivacy',
    pathname: '/services/privacy',
  },
  {
    name: 'serviceRecruitment',
    pathname: '/services/recruitment-privacy',
  },
  {
    name: 'serviceVendor',
    pathname: '/services/vendor-privacy',
  },
  {
    name: 'changePassword',
    pathname: '/users/change-password',
  },
  {
    name: 'marketingConsent',
    pathname: '/users/marketing-consent',
  },
  {
    name: 'securitySetting',
    pathname: '/users/security',
  },
  {
    name: 'settings',
    pathname: '/users/settings',
  },
  {
    name: 'selectPolicy',
    pathname: '/create/policy',
  },
  {
    name: 'selectProfile',
    pathname: '/create/policy/profile',
  },
  {
    name: 'createPolicy',
    pathname: '/create/policy/[token]/[step]',
  },
  {
    name: 'confirmPolicy',
    pathname: '/confirm/policy/[token]',
  },
  {
    name: 'getPolicy',
    pathname: '/get/policy/[token]',
  },
  {
    name: 'dashboardPolicies',
    pathname: '/dashboard/policies',
  },
  {
    name: 'dashboardPolicy',
    pathname: '/dashboard/policies/[token]',
  },
  {
    name: 'policyCustomize',
    pathname: '/dashboard/policies/[token]/customize',
  },
  {
    name: 'customClause',
    pathname: '/dashboard/policies/[token]/custom-clauses',
  },
  {
    name: 'editCustomClause',
    pathname: '/dashboard/policies/[token]/custom-clauses/[id]',
  },
  {
    name: 'newCustomClause',
    pathname: '/dashboard/policies/[token]/custom-clauses/new',
  },
  {
    name: 'customerPolicy',
    pathname: '/policies/view/[lang]/[token]',
  },
  {
    name: 'policyCheckout',
    pathname: '/dashboard/policies/[token]/checkout',
  },
  {
    name: 'policyPayment',
    pathname: '/dashboard/policies/[token]/payments/[transactionToken]',
  },
  {
    name: 'paymentPending',
    pathname: '/dashboard/policies/[token]/payments/[transactionToken]/pending',
  },
  {
    name: 'paymentSuccess',
    pathname: '/dashboard/policies/[token]/payments/[transactionToken]/success',
  },
  {
    name: 'dashboardProfiles',
    pathname: '/dashboard/profiles',
  },
  {
    name: 'dashboardProfile',
    pathname: '/dashboard/profiles/[profileId]',
  },
  ...authRoutes,
]
