import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import COLORS from './colors'

import components from './components'

export const breakpoints = createBreakpoints({
  sm: '360px',
  md: '768px',
  lg: '1024px',
  'lg+': '1280px',
  xl: '1440px',
  xxl: '1920px',
  '3xl': '2560px',
  '4xl': '3440px',
})

const customTheme = extendTheme({
  fonts: {
    normal: "'Helvetica Neue', Helvetica, Arial, Thonburi, Tahoma",
    heading: 'Anakotmai, sans-serif',
    body: 'Anakotmai, sans-serif',
  },
  colors: COLORS,
  breakpoints,
  components,
})

export default customTheme
