export default {
  sizes: {
    xl: {
      fontSize: 'md',
      h: 16,
      px: 4,
      fontWeight: 500,
    },
  },
}
