import { getContrast } from 'polished'

export const readableColor = (
  baseColor: string,
  ifLightColor: string,
  ifDarkColor: string,
): string => {
  const contrastRatio = getContrast(baseColor, '#fff')

  return contrastRatio > 2.2 ? ifLightColor : ifDarkColor
}
