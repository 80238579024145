import React from 'react'

import { userAuth, getUserData } from '@/lib/auth/helpers'
import { AuthContext } from './AuthContext'
import * as UserServices from '../../features/user/services'

function withAuth(PageComponent) {
  function EnhancedPageComponent(props) {
    return (
      <AuthContext.Provider value={{ isAuth: props.isAuth }}>
        <PageComponent {...props} />
      </AuthContext.Provider>
    )
  }

  EnhancedPageComponent.getInitialProps = async function (appContext) {
    let appProps = {}

    const ctx = appContext.ctx
    const isAuth = await userAuth(ctx)
    const userData = getUserData(ctx)

    let userInfo = null
    if (isAuth) {
      userInfo = await UserServices.getUserInfo(userData)
    }

    ctx.isAuth = isAuth
    ctx.needChangePassword = userInfo && userInfo.need_change_password
    ctx.userData = userData

    if (typeof PageComponent.getInitialProps === 'function') {
      appProps = await PageComponent.getInitialProps(appContext)
    }

    return { ...appProps, isAuth, userInfo }
  }

  return EnhancedPageComponent
}

export default withAuth
