export const authRoutes = [
  {
    name: 'login',
    pathname: '/login',
  },
  {
    name: 'signup',
    pathname: '/signup',
  },
  {
    name: 'forgot',
    pathname: '/forgot',
  },
  {
    name: 'reset',
    pathname: '/reset',
  },
  {
    name: 'confirm',
    pathname: '/confirm',
  },
]
