import NextLink from 'next/link'

import { getPathname, getHref } from './utils'

export const Link = (props: any) => {
  const { route, params = {}, ...restProps } = props

  const pathname = getPathname(route)
  const href = getHref(pathname, params)

  return <NextLink {...restProps} href={href} />
}
