import { CSSObject } from '@chakra-ui/react'

export const COOKIE_HIDE_CROSS_SALE_BANNER = 'hideCloseSaleBanner'
export const COOKIE_HIDE_COOKIE_ADS_BANNER = 'hideCwcBanner'

export const hiddenInputStyle: CSSObject = {
  border: '0px',
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0px',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}

export const SORT_OPTIONS = [
  {
    value: 'created_at',
    label: 'สร้างล่าสุด',
  },
  {
    value: 'updated_at',
    label: 'แก้ไขล่าสุด',
  },
]
