import { NextSeoProps } from 'next-seo'

import envConfig from '@/config/env'

export const DEFAULT_SEO_CONFIG: NextSeoProps = {
  titleTemplate: '%s | PDPA.Pro',
  description:
    'PDPA PRO เครื่องมือสร้างแบบฟอร์ม Privacy Policy ได้ด้วยตัวเอง ง่าย สะดวก ตอบโจทย์ทุกความต้องการ เหมือนมืออาชีพ สามารถนำไปใช้งานได้ทันที ไม่ต้องรอ',
  openGraph: {
    type: 'website',
    locale: 'th_TH',
    site_name: 'PDPAPro',
    images: [
      {
        url: `${envConfig.APP_URL}/images/og/default-og.png`,
        width: 1200,
        height: 630,
      },
    ],
  },
  noindex: envConfig.APP_URL !== 'https://pdpa.pro',
}
