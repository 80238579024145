import { find } from 'lodash'
import { useRouter } from 'next/router'

import { routes } from '@/features/routes'

type UseActiveRouteReturn = (targetRoutes: string[]) => boolean

// TODO: handle undefined route name

export const getHref = (pathname: string, params = {}) => {
  return { pathname, query: params }
}

export const getRouteName = (pathname: string) => {
  const routeObject = find(routes, (route) => route.pathname === pathname)

  return routeObject?.name
}

export const getPathname = (route: string): string => {
  return find(routes, (r) => r.name === route)?.pathname || ''
}

export const useActiveRoute = (): UseActiveRouteReturn => {
  const { route } = useRouter()
  const currentRoute = getRouteName(route) ?? ''

  return (targetRoutes: string[]) => {
    return targetRoutes.includes(currentRoute)
  }
}
