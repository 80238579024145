import { get } from 'lodash'

import envConfig from '@/config/env'

import { fetchAPI } from '../api/helpers'
import getUserCookie from './getUserCookie'

export const login = ({ email, password, token }) => {
  return fetchAPI({
    path: '/auth/sign_in',
    method: 'post',
    data: {
      email,
      password,
      token,
    },
  })
}

export const logout = () => {
  const userData = getUserCookie()

  return fetchAPI({
    path: '/auth/sign_out',
    method: 'delete',
    headers: {
      'access-token': get(userData, 'ac'),
      client: get(userData, 'client'),
      uid: get(userData, 'uid'),
    },
  })
}

export const signup = ({ email, password, token, marketing_consent }) => {
  return fetchAPI({
    path: '/auth',
    method: 'post',
    data: {
      email,
      password,
      token,
      marketing_consent,
    },
  })
}

export const forgotPassword = ({ email }) => {
  return fetchAPI({
    path: '/auth/password',
    method: 'post',
    data: {
      email,
      redirect_url: envConfig.APP_URL + '/reset',
    },
  })
}

export const resetPassword = ({
  password,
  confirmPassword,
  resetPasswordToken,
}) => {
  return fetchAPI({
    path: '/auth/password',
    method: 'put',
    data: {
      password,
      password_confirmation: confirmPassword,
      reset_password_token: resetPasswordToken,
    },
  })
}

export const changePassword = ({
  currentPassword,
  password,
  confirmPassword,
}) => {
  return fetchAPI({
    path: '/auth',
    method: 'put',
    data: {
      current_password: currentPassword,
      password,
      password_confirmation: confirmPassword,
    },
  })
}

export function validateToken(userData) {
  return fetchAPI({
    path: '/auth/validate_token',
    method: 'get',
    headers: {
      'access-token': get(userData, 'ac'),
      client: get(userData, 'client'),
      uid: get(userData, 'uid'),
    },
  }).then(({ data }) => {
    return get(data, 'success', false)
  })
}

export const getConfirmationInfo = ({ confirmationToken, userData }) => {
  return fetchAPI({
    path: '/auth/confirmation',
    method: 'get',
    params: { confirmation_token: confirmationToken },
    userData,
  }).then(({ data }) => data)
}

export const confirm = ({
  password,
  confirmPassword,
  confirmationToken,
  marketingConsent,
}) => {
  return fetchAPI({
    path: '/auth/confirmation',
    method: 'put',
    data: {
      password,
      password_confirmation: confirmPassword,
      confirmation_token: confirmationToken,
      marketing_consent: marketingConsent,
    },
  })
}
